import { Injectable } from "@angular/core";
import Keycloak from "keycloak-js";
import { KeycloakInstance, KeycloakProfile } from "keycloak-js";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../../dashboard/models/user";
import { environment } from "../../../environments/environment";
import { MandantEnum } from "../models/mandant.enum";
import { Benutzertyp } from "../models/benutzertyp.enum";

@Injectable({
  providedIn: "root",
})
export class KeycloakService {
  public keycloakInstance: KeycloakInstance;
  public benutzerTyp$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public userId$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private readonly REDIRECT_URI = window.location.href;

  /**
   * Implements keycloak init and returns KeyCloakResponse as promise, which includes loggedIn attribute
   */
  initKeycloak(): Promise<KeycloakResponse> {
    this.initKeycloakInstance();
    const initobj: Keycloak.KeycloakInitOptions = {
      onLoad: "login-required",
      checkLoginIframe: false,
      redirectUri: this.REDIRECT_URI,
    };
    return this.keycloakInstance
      .init(initobj)
      .then((loggedInResult: boolean) => {
        // todo remove loadUserInfo and loadUserProfile and get data from ZobUser
        this.keycloakInstance.loadUserInfo().then(() => {}); // triggers async pre-loading of user info
        this.keycloakInstance.loadUserProfile().then(() => {
          this.benutzerTyp$.next(this.getBenutzertypFromProfile());
          this.userId$.next(this.keycloakInstance.profile.username);
        });
        return {
          loggedIn: loggedInResult,
          idmId: this.keycloakInstance.subject,
        } as KeycloakResponse;
      })
      .catch(() => {
        return {
          loggedIn: false,
          idmId: this.keycloakInstance.subject,
        } as KeycloakResponse;
      });
  }

  /**
   * Tiggers an update of keycloak token in keycloak instance and returns it in a promise
   */
  updateToken(): Promise<string> {
    return this.keycloakInstance
      .updateToken(20)
      .then(() => {
        return this.keycloakInstance.token;
      })
      .catch(() => {
        throw new Error("Keycloak Token could not be updated");
      });
  }

  /**
   * todo implement logout
   */
  logout(): void {
    this.keycloakInstance.logout({ redirectUri: this.REDIRECT_URI });
  }

  /**
   * @deprecated use {@link UserService#getVbNummer|UserService.getVbNummer()}
   * Returns observable of the vbNummer of logged in VB, obtained via keycloak userInfo
   */
  getVbNummer(): Observable<string> {
    return this.getKeyFromUserInfo("preferred_username");
  }

  /**
   * @deprecated use {@link UserService#getMandant|UserService.getMandant()}
   * Returns obervable of mandant of which VB is part of
   */
  getMandant(): Observable<MandantEnum> {
    if (environment.environment.includes("vdl-")) {
      // default for Aachen environments, bc no reliable info from Keycloak there
      return of(MandantEnum.DVAG);
    }
    if (this.keycloakInstance.profile) {
      return of(this.extractMandantFromKeycloakProfile(this.keycloakInstance.profile));
    } else {
      return from(this.keycloakInstance.loadUserProfile()).pipe(
        map((userProfile) => {
          return this.extractMandantFromKeycloakProfile(userProfile);
        }),
      );
    }
  }

  /**
   * @deprecated no replacement yet. See {@link UserService|UserService}
   */
  getUserData(): Promise<User> {
    if (this.keycloakInstance.profile) {
      return new Promise<User>((resolve) => {
        const user = this.mapKeycloackProfileToUserData(this.keycloakInstance.profile);
        resolve(user);
      });
    } else {
      return new Promise<User>((resolve) => {
        this.keycloakInstance.loadUserProfile().then((profile) => {
          const user = this.mapKeycloackProfileToUserData(profile);
          resolve(user);
        });
      });
    }
  }

  getBenutzertypFromProfile(): string {
    if (environment.environment.includes("vdl-")) {
      return Benutzertyp.AUSSENDIENST;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore attributes not on type KeycloakProfile, but sent from keycloak server
    return this.keycloakInstance.profile.attributes.benutzertyp?.[0] ?? "";
  }

  initKeycloakInstance(): void {
    this.keycloakInstance = new Keycloak({ ...environment.keycloakConfig });
  }

  /**
   * @deprecated use {@link UserService#isHauptnutzer|UserService.isHauptnutzer()}
   *
   */
  isHauptnutzer(): boolean {
    return this.benutzerTyp$.getValue() === "AUSSENDIENST";
  }

  private getKeyFromUserInfo(key: string): Observable<string> {
    if (this.keycloakInstance.userInfo) {
      return of(this.keycloakInstance.userInfo[key]);
    } else {
      return from(this.keycloakInstance.loadUserInfo()).pipe(map((info) => info[key]));
    }
  }

  /**
   * @deprecated no replacement yet. See {@link UserService|UserService}
   */
  private mapKeycloackProfileToUserData(kcProfile: KeycloakProfile): User {
    const user = new User();
    user.vorname = kcProfile.firstName;
    user.name = kcProfile.lastName;
    user.userid = kcProfile.username;
    user.email = kcProfile.email;
    return user;
  }

  /**
   * @deprecated no replacement yet. See {@link UserService|UserService}
   */
  private extractMandantFromKeycloakProfile(profile: KeycloakProfile): MandantEnum {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore attributes not on type KeycloakProfile, but sent from keycloak server
    switch (profile.attributes.gesellschaft[0]) {
      case "1":
      case "2":
        return MandantEnum.DVAG;
      case "3":
        return MandantEnum.ALLFINANZ;
      case "5":
        return MandantEnum.ALLFINANZ_AG;
      default:
        return MandantEnum.DVAG;
    }
  }
}

export interface KeycloakResponse {
  loggedIn: boolean;
  idmId: string;
}
